<script setup>
import { ref, reactive } from 'vue'
import { postSetPassword } from '@/api/login/login.js'
import { isEmoji } from '@/utils/generalReg'
import { i18n } from '@/setup/i18n-setup'

const mbFormRef = ref(null)
const visiblePassword = ref(false)
const passwordFrom = reactive({
  password: '',
  repeatPassword: ''
})

const passwordRule = {
  password: [
    {
      min: 6,
      trigger: ['blur'],
      required: true,
      validator: (rule, value, callback) => {
        if (value === '') {
          callback(new Error(i18n.t('e39ffe99e93cb6cc8d5978ed21660740')))
        } else {
          callback()
        }
      }
    },
    {
      validator: (_, value, callback) => {
        if (isEmoji(value)) {
          return callback(new Error('不能输入 emoji 表情'))
        } else {
          return callback()
        }
      },
      trigger: ['blur', 'change']
    }
  ],
  repeatPassword: [
    {
      min: 6,
      trigger: ['blur'],
      required: true,
      validator: (rule, value, callback) => {
        if (value === '') {
          callback(new Error(i18n.t('a24ab09b63d54de6a629afe1fcd69a0f')))
        } else if (value !== passwordFrom.password) {
          callback(new Error(i18n.t('7dbe3fe207a75383792ff599ee25fddd')))
        } else {
          callback()
        }
      }
    }
  ]
}

const handleSetPassword = () => {
  mbFormRef.value?.validate((valid) => {
    if (!valid) return
    const { password, repeatPassword } = passwordFrom
    postSetPassword({ password, repeatPassword }).then(() => {
      visiblePassword.value = false
      localStorage.setItem('hasPassword', 'true')
    })
  })
}

const show = (visible = false) => {
  visiblePassword.value = visible
}

// eslint-disable-next-line no-undef
defineExpose({
  show
})
</script>

<template>
  <!-- 首次进来没有设置密码 -->
  <mb-dialog v-model="visiblePassword" :title="$t('f114c73fd61a1635d201ddd29a9846fe')" @submit="handleSetPassword" @cancel="visiblePassword = false">
    <el-alert :closable="false" show-icon style="margin-bottom: 16px" :title="$t('c32f65f47a4d855f2e8d253bb35e119b')" type="info"></el-alert>
    <mb-form ref="mbFormRef" :model="passwordFrom" :rules="passwordRule" label-width="160px">
      <el-form-item :label="$t('bb8a46328efe771678097229a0c5b1d9')" prop="password">
        <el-input v-model="passwordFrom.password" minLength="6" :placeholder="$t('bb8a46328efe771678097229a0c5b1d9')" type="password"></el-input>
      </el-form-item>
      <el-form-item :label="$t('92fa7ce5c5cfbd7c1aef8ea0018a81c9')" prop="repeatPassword">
        <el-input v-model="passwordFrom.repeatPassword" minLength="6" :placeholder="$t('92fa7ce5c5cfbd7c1aef8ea0018a81c9')" type="password"></el-input>
      </el-form-item>
    </mb-form>
  </mb-dialog>
</template>
