import { setUserInfo } from '@/utils/localStorage'

const state = {
  userInfo: {
    avatar: null,
    birthday: null,
    gender: 'SECRECY',
    mail: null,
    name: null
  }
}

const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
    setUserInfo(userInfo)
  }
}

// const actions = {
//   setuserInfo({ commit }) {}
// }

export default {
  namespaced: true,
  state,
  mutations
  //   actions
}
