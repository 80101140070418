// import { store } from '@/store/index.js'
import { getDomainTheme, getToken } from '@/utils/localStorage'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
const { isNavigationFailure, NavigationFailureType } = VueRouter

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/profile/index'),
        meta: {
          title: '409120b562eac58d04bbca0fb5f24f52'
        }
      },
      // 用户没有企业的情况下，进入企业入驻页面
      {
        path: 'enterEnterPrise',
        name: 'enterEnterPrise',
        component: () => import(/* webpackChunkName: "enterEnterPrise" */ '../views/enterEnterPrise/index'),
        meta: {
          title: '409120b562eac58d04bbca0fb5f24f52'
        }
      },
      // 消息通知列表
      {
        path: 'notificationList',
        name: 'notificationList',
        component: () => import(/* webpackChunkName: "notificationList" */ '../views/notificationList/YMain.vue'),
        meta: {
          title: 'e9cef3e66e961a14e09d97846e4b2c12'
        }
      }
    ]
  },
  {
    path: '/basic',
    name: 'basic',
    component: Home,
    children: [
      {
        path: 'basic_organization',
        name: 'basic_organization',
        component: () => import(/* webpackChunkName: "basic_organization" */ '../views/organization/index'),
        meta: {
          title: '组织架构'
        }
      },
      {
        path: 'basic_OTPLog',
        name: 'basic_OTPLog',
        component: () => import(/* webpackChunkName: "basic_OTPLog" */ '../views/opt_log/index'),
        meta: {
          title: '验证码查询'
        }
      },
      {
        path: 'I18NManage',
        name: 'I18NManage',
        component: () => import(/* webpackChunkName: "I18NManage" */ '../views/i18n_manage/main'),
        meta: {
          title: '国际化'
        }
      },
      {
        path: 'basic_appUpdateLog',
        name: 'basic_appUpdateLog',
        component: () => import(/* webpackChunkName: "basic_appUpdateLog" */ '../views/appUpdateLog/index.vue'),
        meta: {
          title: 'app更新日志'
        }
      },
      {
        path: 'basic_esLog',
        name: 'basic_esLog',
        component: () => import(/* webpackChunkName: "basic_esLog" */ '../views/esLog/index.vue'),
        meta: {
          title: 'app更新日志'
        }
      },
      {
        path: 'basic_QA',
        name: 'basic_QA',
        component: () => import(/* webpackChunkName: "basic_QA" */ '../views/qa/main.vue'),
        meta: {
          title: 'QA管理'
        }
      },
      {
        path: 'basic_H5',
        name: 'basic_H5',
        component: () => import(/* webpackChunkName: "basic_H5" */ '../views/custom_h5/main.vue'),
        meta: {
          title: 'H5'
        }
      },
      {
        path: 'basic_smsLog',
        name: 'basic_smsLog',
        component: () => import(/* webpackChunkName: "basic_smsLog" */ '../views/basic/basic_smsLog/index.vue'),
        meta: {
          title: 'smsLog'
        }
      },
      {
        path: 'basic_orgConfig',
        name: 'basic_orgConfig',
        component: () => import(/* webpackChunkName: "basic_orgConfig" */ '../views/basic/basic_orgConfig/index.vue'),
        meta: {
          title: 'orgConfig'
        }
      },
      {
        path: 'basic_orgApplication',
        name: 'basic_orgApplication',
        component: () => import(/* webpackChunkName: "basic_orgApplication" */ '../views/basic/basic_orgApplication/index.vue'),
        meta: {
          title: '机构申请审核'
        }
      },

      {
        path: 'basic-loginlog',
        name: 'basic-loginlog',
        component: () => import(/* webpackChunkName: "basic-loginlog" */ '../views/basic/basic_login_log/index.vue'),
        meta: {
          title: 'loginlog'
        }
      },
      {
        path: 'basic-onlineuser',
        name: 'basic-onlineuser',
        component: () => import(/* webpackChunkName: "basic-onlineuser" */ '../views/basic/basic_online_user/index.vue'),
        meta: {
          title: 'onlineuser'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ '../views/test/index.vue')
  },
  {
    path: '*',
    name: 'subApp-micro',
    component: Home
  }
]

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

const router = createRouter()

const _push = router.push
router.push = (...rest) => {
  _push.call(router, ...rest)?.catch((err) => {
    if (!isNavigationFailure(err, NavigationFailureType.duplicated)) {
      // console.error('hahah')
      console.error(err)
    }
  })
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

const whiteList = ['/login', '/enterEnterPrise']
router.beforeEach(async (to, from, next) => {
  const domain = getDomainTheme()
  const title = domain?.title
  if (title) {
    document.title = title
  }
  if (whiteList.includes(to.path)) {
    return next()
  } else if (getToken()) {
    // 处理组件名，用于keep-alive
    const tRoute = to.matched.find((m) => m.path === to.path)
    if (typeof tRoute?.components.default === 'function') {
      const d = await tRoute.components.default()
      if (!d.default.name || /index/i.test(d.default.name)) {
        d.default.name = /index/i.test(d.default.name) ? `${d.default.name}-${Date.now()}` : to.name
      }
      to.meta.componentName = d.default.name
    }
    return next()
  } else {
    return next({ path: '/login' })
  }
})

export default router
