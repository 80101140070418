// 主应用和子应用通信
// https://qiankun.umijs.org/zh/api#initglobalstatestate

import Vue from 'vue'
import { initGlobalState } from 'qiankun'

// 全局状态
const state = {
  subMounted: false
}

// 初始化 state
const actions = initGlobalState(state)

// 监听子应用的发送过来的信息
// actions.onGlobalStateChange((newVal) => {
//   console.log(newVal, '子应用发来消息拉')
// })

// 主应用向子应用通信
export const qiankunSetGlobalState = (payload = {}) => {
  const s = Object.assign(state, {}, payload)
  actions.setGlobalState(s)
  // actions.offGlobalStateChange()
}

Vue.prototype.$qiankunSetGlobalState = qiankunSetGlobalState
