<script setup>
/*global defineProps*/
import { ref } from 'vue'

defineProps({
  src: {
    type: String,
    default: ''
  },
  srcList: {
    type: Array,
    default: () => []
  }
})

const elImageRef = ref(null)
</script>

<template>
  <div class="preview-image-for-api">
    <el-image ref="elImageRef" style="width: 100px; height: 100px" :src="src" :preview-src-list="srcList"> </el-image>
  </div>
</template>

<style>
.preview-image-for-api {
  display: none;
}
</style>
