<script setup>
/*global defineProps */
import { watch, nextTick } from 'vue'

const props = defineProps({
  links: {
    type: Array,
    default: () => []
  },
  active: {
    type: Object,
    default: () => null
  }
})

watch(props, () => {
  nextTick(() => {
    const ele = document.querySelector('.is-link:not(.notShow)')?.querySelector('iframe')
    if (ele) {
      ele.onload = () => {
        const l = props.links.find((item) => item.id === props.active.id)
        l.loading = false
      }
    }
  })
})

const handleShow = (link) => {
  return (props.active || {}).linkUrl === link.linkUrl
}

const handleOpen = (link) => window.open(link.linkUrl)
</script>

<template>
  <div class="outside-link" v-show="active" style="width: 100%; height: 100%">
    <template v-for="item in links">
      <div v-loading="item.loading" :key="item.id" v-show="handleShow(item)" class="is-link" :class="handleShow(item) ? '' : 'notShow'">
        <iframe :src="item.linkUrl" frameborder="0"></iframe>
        <div class="tip" v-if="item.tipShow">
          <div class="l">
            <i class="el-icon-info" style="margin-right: 8px"></i>
            如无法正常使用，可以站外打开
          </div>
          <div class="r">
            <el-button type="text" @click="handleOpen(item)">站外打开</el-button>
            <i class="el-icon-close" @click="item.tipShow = false"></i>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.is-link {
  position: relative;
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
  }
  .tip {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    font-size: 12px;
    background: #ebeef5;
    border-radius: 4px;
    transform: translateX(-50%);
    z-index: 9999;
    .l {
      margin-right: 99px;
      color: #909399;
      .el-icon-info {
        color: #909399;
      }
    }
    .r {
      .el-icon-close {
        margin-left: 16px;
        &:hover {
          cursor: pointer;
          color: var(--primary-color);
        }
      }
    }
  }
}
</style>
