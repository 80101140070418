import { store } from '@/store/index.js'

// 通过路径找到对应的解决方案
export const findSolutionForPath = (path, menus = store.state.menus) => {
  for (const item of menus) {
    if (item.type === 'FOLDER') {
      if (item.children?.length) {
        const r = findSolutionForPath(path, item.children)
        if (r) {
          return r
        }
      }
    } else if (item.type === 'MENU') {
      if (item.path === path) {
        return item
      }
    }
  }
}
