import { loadMicroApp } from 'qiankun'
import { store } from '@/store'
export * from './pubsub'
import BasicBpcComponent from '@mybeauty/basic-bpc-component'
import { nextTick } from 'vue'
import { useLoading } from '@/utils/loading'
import btnAuth from '@/directives/auth'
import { qiankunSetGlobalState } from './pubsub'

const { startLoading, closeLoading } = useLoading()

let apps = null
// let microInstance = {}

// 本地启动子应用的资源
const localApps = [
  {
    name: 'goodthinghub',
    url: '//localhost:30001'
  },
  {
    name: 'iop',
    url: '//localhost:30002'
  },
  {
    name: 'cv',
    url: '//localhost:30003'
  },
  {
    name: 'labsys',
    url: '//localhost:30004'
  }
].map((app) => {
  app.appCode = app.name
  app.type = 'BPC_SUBWEB'
  return app
})

export const registerMicro = (args) => {
  store.commit('resetMicroApps')
  const microApps = []
  // 本地环境和线上环境区分开
  apps = (['local', 'preview'].includes(process.env['VUE_APP_ENV']) ? localApps : args)
    // 过滤掉不是bpc子应用的项目
    .filter((arg) => arg.type === 'BPC_SUBWEB')
    .map((item) => {
      item.entry = item.url
      item.activeRule = `/${item.appCode}`
      item.container = '#subapp'
      item.props = {
        activeRule: item.appCode, // 下发给子应用的base路由
        BasicBpcComponent,
        // 子应用也使用父应用的loading，避免出现多个loading
        useLoading,
        // 下发按钮权限指令
        btnAuth
      }
      microApps.push(item.activeRule)
      return item
    })
  store.commit('setMicroApps', microApps)
}

export function startApp(args) {
  const { activeRule } = args
  const app = apps.find((a) => a.activeRule === activeRule)
  if (!app) return
  const { microInstance } = store.state
  if (!microInstance[app.name]) {
    const startTime = Date.now()
    const unique = 'micro app loading'
    nextTick(() => startLoading({ unique }))
    console.log(app, 'app')
    const microApp = loadMicroApp(app, {
      excludeAssetFilter: (assetUrl) => {
        return assetUrl.includes('maps.googleapis.com')
      }
    })
    store.commit(
      'setMicroInstance',
      Object.assign({}, microInstance, {
        [app.name]: microApp
      })
    )
    // 在父应用中判断子应用的加载情况，MOUNTED的时候关闭loading
    let id
    const microMounted = () => {
      closeLoading(unique)
      window.cancelAnimationFrame(id)
    }
    let minutes = 60
    if (process.env.isVite) {
      minutes = 1
    }
    const frame = () => {
      // 加载子应用30s后任然加载不出来就关闭loading
      if (Date.now() - startTime >= minutes * 1000) {
        console.error(`子应用加载超时 ${minutes}s 关闭loading`)
        microMounted()
        return
      }
      const status = microApp?.getStatus()
      // console.log(status)

      if (!status) {
        microMounted()
        return
      }
      if (status === 'MOUNTED') {
        microMounted()
        qiankunSetGlobalState()
      } else {
        window.requestAnimationFrame(frame)
      }
    }
    id = window.requestAnimationFrame(frame)
  } else {
    if (microInstance[app.name].getStatus() === 'NOT_MOUNTED') {
      store.state.microInstance[app.name].mount().then(() => {
        qiankunSetGlobalState()
      })
    }
  }
}
