import { ref } from 'vue'
import { Notification } from '@/setup/elementUI'
import router from '@/router/index'
import MqttTools from './mqtt'
import { getUserInfo } from '@/utils/localStorage'
import { getNotificationInnerMessage, postNotificationRead, postNotificationReadAll } from '@/api/basic/notification'

const visibleNotificationDialog = ref(false)
const hasNotification = ref(false)
const nData = ref([])

document.addEventListener('click', () => {
  if (visibleNotificationDialog.value) {
    visibleNotificationDialog.value = false
  }
})

const initNotification = async () => {
  const userInfo = getUserInfo()
  const clientId = 'bpc_mqttjs_' + userInfo.code + `_${Date.now()}`
  const mqtt = new MqttTools({
    clientId
  })
  ;[
    // `web/goodthinghub/${userInfo.code}/${userInfo.currentOrganizationId}/inner_message`,
    `web/goodthinghub/${userInfo.code}/${userInfo.currentEmployeeId}/inner_message`,
    `web/goodthinghub/${userInfo.code}/inner_message`
  ].forEach((item) => {
    mqtt.subscribe(item, (_, message) => {
      miniNotificationShow(JSON.parse(message))
    })
  })
  notificationShow()
}

const unsubscribe = () => {
  if (MqttTools.instance) {
    MqttTools.instance?.unsubscribe()
  }
}

const disconnect = () => {
  if (MqttTools.instance) {
    MqttTools.instance.unsubscribe()
    MqttTools.instance.disconnect()
  }
}

// 右下角的通知
const miniNotificationShow = (params = {}) => {
  notificationShow()
  // hasNotification.value = true
  const obj = {
    title: params.title,
    message: params.content,
    ...params
  }
  Notification({
    title: obj.title,
    message: obj.message,
    position: 'bottom-right',
    duration: 1000 * 10,
    onClick() {
      clickNotification(obj)
      this.close()
    }
  })
}

const notificationShow = (params = {}) => {
  getNotificationInnerMessage(params).then((res) => {
    nData.value = res.data.content
    checkedHasNotification()
  })
}

const checkedHasNotification = () => {
  if (!nData.value.length) {
    hasNotification.value = false
    return
  }
  hasNotification.value = nData.value.some((item) => !item.read)
}

const clickNotification = (n) => {
  postNotificationRead({ msgId: n.id }).then(() => {
    n.read = true
    checkedHasNotification()
  })
  if (n.actionType === 'OUTER') {
    window.open(n.url, '_blank')
  } else if (n.actionType === 'INNER' && n.url) {
    // 目前只有 预约 消息
    router.push({
      path: n.url,
      query: {
        // ...n.params
      }
    })
  }
}

const readAllNotification = () => {
  postNotificationReadAll().then(() => {
    nData.value.forEach((item) => (item.read = true))
    hasNotification.value = false
  })
}

export {
  nData,
  visibleNotificationDialog,
  hasNotification,
  notificationShow,
  checkedHasNotification,
  clickNotification,
  initNotification,
  readAllNotification,
  unsubscribe,
  disconnect
}
