// 按钮权限

const state = {
  buttonList: []
}

const mutations = {
  SET_BUTTON_LIST: (state, list) => {
    list.forEach((item) => {
      const index = state.buttonList.findIndex((button) => button.id === item.id)
      if (index !== -1) {
        state.buttonList.splice(index, 1, item)
      } else {
        state.buttonList.push(item)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
