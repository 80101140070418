<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { queryCurrentAccount, queryCurrentOrganization, queryCurrentSolutionResource, switchI18n, updateSwitchOrganization } from '@/api/base.js'
import { languages } from '@/i18n/language'
import { loadLanguageAsync, i18n } from '@/setup/i18n-setup'
import PubSub from 'pubsub-js'
import MlMenu from '@/components/menu/ml-menu'
import { startApp, qiankunSetGlobalState } from '@/microApp/index'
import { store } from '@/store/index.js'
import { getDomainTheme, logout, setProjectList, setSolutions, getCurrentSolution, setCurRoute, getCurRoute } from '@/utils/localStorage'
import changeThemeVue from './changeTheme.vue'
import checkPasswordVue from './checkPassword.vue'
import isLinkVue from './isLink.vue'
import { insertedThemeLink } from './setup'
import { findSolutionForPath } from './setupFindSolution'
import { getQaExistUri } from '@/api/qa'
import qaDrawerVue from './qaDrawer.vue'
import tips from '@/assets/images/tips.gif'
import QRCode from 'qrcodejs2-fix'
import { hasNotification, notificationShow, visibleNotificationDialog, initNotification, unsubscribe } from './notification.setup'
import NotificationDialog from './NotificationDialog.vue'

const route = useRoute()
const router = useRouter()

const cachedQa = ref({})
const qaDrawerRef = ref(null)
const checkPasswordRef = ref(null)
const localeActive = ref(languages[0].label)
const domainTheme = ref(getDomainTheme() || {})
const tabs = ref([])
const organization = ref([])
const currentOrganization = ref({
  name: '',
  contactTelephone: ''
})
const solutions = ref([])
const currentSolution = ref({})
const accountInfo = ref({})
const tabActive = ref('')
const collapse = ref(false)
const keepAliveIncludes = ref([])
const shouldRefresh = ref(Date.now())

const userInfo = computed(() => store.state.user.userInfo)
const _path = computed(() => route.path)
const isMicro = computed(() => !!store.state.microApps.find((item) => route.path.startsWith(item)))
const activeRule = computed(() => store.state.microApps.find((item) => route.path.startsWith(item)))
// 是否是外链
const link = ref(null)
const links = ref([])

watch(
  link,
  (val) => {
    if (val && !links.value.some((item) => item.id === val.id)) {
      links.value.push({
        ...val,
        loading: true,
        tipShow: true
      })
    }
  },
  { immediate: true, deep: true }
)

watch(isMicro, (val) => {
  if (val) {
    startApp({ activeRule: activeRule.value })
  }
})

// 标签栏
const dealTabs = (val) => {
  let whiteList = ['/profile', '/notificationList']
  let s = findSolutionForPath(val)
  link.value = s?.link ? s : null

  // 单独处理个人中心的标签栏，因为个人中心的路径不是解决方案中的，国际化需要单独处理
  if (!s && whiteList.includes(val)) {
    s = {
      displayName: i18n.t(route.meta.title),
      path: val,
      title: route.meta.title
    }
  }
  if (s) {
    const hasTab = tabs.value.some((tab) => tab.path === s.path)
    if (!hasTab) {
      tabs.value.push(s)
      keepAliveIncludes.value.push({
        componentName: route.meta.componentName,
        path: s.path
      })
      qiankunSetGlobalState({ keepAliveIncludes: keepAliveIncludes.value })
    }
    tabActive.value = s.path
  } else {
    const hasTab = tabs.value.some((tab) => tab.path === val)
    if (!hasTab && val !== '/') {
      tabs.value.push({
        path: val,
        displayName: i18n.t('2b68615b34fac3f9caa72cecb4350ece')
      })
      tabActive.value = val
    }
  }
}

watch(
  _path,
  (val) => {
    setCurRoute(val)
    dealTabs(val)
    if (cachedQa.value[val] === undefined) {
      getQaExistUri(val).then((res) => {
        cachedQa.value = {
          [val]: res.data,
          ...cachedQa.value
        }
      })
    }
  },
  { immediate: true }
)

onMounted(() => {
  insertedThemeLink().then(async () => {
    // 新用户进驻在没有企业的情况下，需要先设置企业
    checkPassword()
    await init()
    const hasOrganization = localStorage.getItem('hasOrganization')
    if (hasOrganization === 'true') {
      initNotification()
      notificationShow()
    }
  })

  // 生成地址二维码
  const url = process.env.VUE_APP_QRCode_URL
  new QRCode(document.querySelector('.qrCodeBox'), {
    text: url,
    width: 180,
    height: 170
  })
})

const init = async () => {
  // 获取当前账号
  await queryCurrentAccount().then(async (res) => {
    const { language } = res.data
    await handleLocale(language || 'zh-CN')
    accountInfo.value = res.data
    store.commit('user/SET_USER_INFO', res.data)
  })
  // 获取当前组织
  await queryCurrentOrganization().then((res) => {
    organization.value = res.data
    currentOrganization.value = organization.value.find((item) => item.id === accountInfo.value.currentOrganizationId) || organization.value[0]
  })
}

const checkPassword = () => {
  const visible = !JSON.parse(localStorage.getItem('hasPassword'))
  checkPasswordRef.value.show(visible)
}

const handleChangeOrganization = (value) => {
  // currentSolution.value = organization.value.find((item) => item.id === value)
  updateSwitchOrganization({ organizationId: value }).then(() => {
    queryCurrentSolutionResource().then((res) => {
      const { solutionMenuVOList, projectList } = res.data
      setSolutions(solutionMenuVOList)
      // setCurrentSolution(solutionMenuVOList[0])
      setProjectList(projectList)
      store.commit('resetState')
      store.commit('registerRouter')
      // mqtt
      unsubscribe()
      window.location.href = '/'
    })
  })
}

const handleChangeSolution = async (value, clearTab = false) => {
  shouldRefresh.value = Date.now()
  keepAliveIncludes.value = []
  store.commit('setCurrentSolution', value)
  await store.dispatch('unmountMicro')
  store.commit('registerRouter')
  currentSolution.value = store.state.currentSolution
  // 切换方案时清空tabs
  if (clearTab !== false) {
    tabs.value = []
  }
  if (!store.state.menus.length) {
    router.push('/')
    return
  }
  // dealTabs(route.path)
  // 刷新页面或者切换国际化时，都应该停留在当前路由
  const r = getCurRoute()
  if (r && findSolutionForPath(r)) {
    router.push(r)
    nextTick(() => {
      startApp({ activeRule: activeRule.value })
    })
    return
  }
  // 如果当前路由不在菜单中，则跳转到第一个菜单
  for (const item of store.state.menus) {
    const menu = findMenu(item)
    if (menu) {
      router.push({ path: menu.path })
      nextTick(() => {
        startApp({ activeRule: activeRule.value })
      })
      return
    }
  }

  // 找到第一个菜单，然后跳转路由
  function findMenu(data) {
    if (data.type === 'MENU') {
      return data
    } else {
      for (const child of data.children) {
        return findMenu(child)
      }
    }
  }
}

const loadLang = (txt) => {
  localeActive.value = languages.find((item) => item.value === txt)?.label || languages[0].label
  loadLanguageAsync(txt).then(() => {
    PubSub.publish('i18nChange')
    qiankunSetGlobalState({ locale: txt })
    dealTabsI18n()
  })
}

const handleLocale = (txt) => {
  return switchI18n(txt).then(() => {
    // 切换之后需要查询资源菜
    if (JSON.parse(localStorage.getItem('hasOrganization'))) {
      queryCurrentSolutionResource().then((res) => {
        const { solutionMenuVOList, projectList } = res.data
        setSolutions(solutionMenuVOList)
        setProjectList(projectList)
        solutions.value = solutionMenuVOList
        // 重新注册菜单
        let cc = getCurrentSolution()
        if (!cc) {
          cc = solutionMenuVOList[0]
        }
        const c = solutionMenuVOList.find((item) => item.id === cc.id)
        handleChangeSolution(c?.id, false)

        loadLang(txt)
      })
    } else {
      loadLang(txt)
    }
  })
}

const tabClick = () => {
  const tabItem = tabs.value.find((tab) => tab.path === tabActive.value)
  if (tabItem) {
    if (isMicro.value) {
      history.replaceState(null, '', tabItem.path)
    } else {
      router.replace(tabItem.path)
    }
  }
}

const tabRemove = (path) => {
  const index = tabs.value.findIndex((tab) => tab.path === path)
  const linkIndex = links.value.findIndex((l) => l.path === path)
  index !== -1 && tabs.value.splice(index, 1)
  linkIndex !== -1 && links.value.splice(linkIndex, 1)

  const kIndex = keepAliveIncludes.value.findIndex((k) => k.path === path)
  if (kIndex !== -1) {
    keepAliveIncludes.value.splice(kIndex, 1)
    qiankunSetGlobalState({ keepAliveIncludes: keepAliveIncludes.value })
  }
  if (path === tabActive.value) {
    const i = index > 0 ? index - 1 : 0
    const path = tabs.value[i].path
    if (isMicro.value) {
      history.replaceState(null, '', path)
    } else {
      router.replace(path)
    }
    tabActive.value = path
  }
}

// 处理tabs国际化
const dealTabsI18n = () => {
  tabs.value.forEach((tab) => {
    const displayName = findSolutionForPath(tab.path)?.displayName
    if (displayName) {
      tab.displayName = displayName
    }
    // fix: 个人中心的tab页签没有变化
    else if (['/profile', '/notificationList'].includes(tab.path)) {
      tab.displayName = i18n.t(tab.title)
    } else {
      tab.lateDelete = true
    }
  })
  tabs.value = tabs.value.filter((tab) => !tab.lateDelete)
}

const showQaDrawer = () => {
  const ele = document.querySelector('.qa-icon')
  if (ele.getAttribute('is-drag')) {
    return
  }
  qaDrawerRef.value.show(_path.value)
}
</script>

<template>
  <el-container class="container" style="height: 100%">
    <el-aside :class="collapse ? 'home-collapse' : ''">
      <div id="logo">
        <img :src="collapse ? domainTheme['logo-small'] : domainTheme.logo" :alt="domainTheme.title" />
      </div>
      <transition>
        <ml-menu v-if="store.state.menus.length" :collapse="collapse" />
        <template v-else>
          <div class="empty-menu">暂无菜单</div>
        </template>
      </transition>
    </el-aside>
    <el-container direction="vertical">
      <el-header class="header" height="44px">
        <div class="left">
          <i :class="['collapse', collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']" @click="collapse = !collapse"></i>
          <!-- 切换企业 -->
          <el-dropdown @command="handleChangeOrganization">
            <span class="el-dropdown-link"
              >{{ (currentOrganization && currentOrganization.name) || $t('1815942c7980e3397e40ca61416ce461') }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu class="mb-dropdown" slot="dropdown">
              <el-dropdown-item :command="item.id" v-for="(item, index) of organization" :key="index">{{ item.name }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- 切换解决方案 -->
          <el-dropdown class="divider" @command="handleChangeSolution">
            <span class="el-dropdown-link"
              >{{ (solutions.length && currentSolution.displayName) || $t('2ae8740b687671a0dcae08ca03dc457c') }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu class="mb-dropdown" slot="dropdown">
              <el-dropdown-item :command="item.id" v-for="(item, index) of solutions" :key="index">{{ item.displayName }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="right">
          <!-- 消息通知 -->
          <el-popover placement="bottom" trigger="manual" v-model="visibleNotificationDialog" @show="notificationShow">
            <NotificationDialog></NotificationDialog>
            <template slot="reference">
              <div class="notification" @click.stop="visibleNotificationDialog = !visibleNotificationDialog">
                <el-badge is-dot :hidden="!hasNotification"><i class="icon-size el-icon-bell"></i></el-badge>
              </div>
            </template>
          </el-popover>

          <div id="qrI">
            <!-- 手机二维码 -->
            <i class="iconfont icon-phone-android" style="cursor: pointer"></i>
            <!-- 二维码卡片 -->
            <el-card class="qrCodeCard">
              <div class="qrCodeTopWord">Scan the qrcode to use the mobile webapp</div>
              <div class="qrCodeBox"></div>
              <div class="qrCodeBotWord">Business Mobile</div>
            </el-card>
          </div>
          <!-- 切换国际化 -->
          <el-dropdown @command="handleLocale">
            <span class="el-dropdown-link"> {{ localeActive }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="language.value" v-for="language in languages" :key="language.value">{{ language.label }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 个人信息 -->
          <el-dropdown>
            <div class="top">
              <div class="avatar">
                <el-image class="haveName" v-if="userInfo.avatar" fit="cover" :src="userInfo.avatar" />
                <div v-else class="noName">{{ userInfo.name && userInfo.name.slice(0, 2) }}</div>
              </div>
              <span class="desc">{{ userInfo.name ? userInfo.name : userInfo.mail ? userInfo.mail : userInfo.phone }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="$router.push({ path: '/profile' })">{{ $t('409120b562eac58d04bbca0fb5f24f52') }}</el-dropdown-item>
              <el-dropdown-item @click.native="$refs.changeThemeRef.openDialog">{{ $t('8e4a4d48449ffb3e7b26d9abea1b6153') }}</el-dropdown-item>
              <el-dropdown-item @click.native="logout">{{ $t('44efd179aa7e6c204c751cfa6c958dd0') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <!-- 页签 -->
      <div class="tabs" v-show="tabs.length">
        <el-tabs v-model="tabActive" type="card" :closable="tabs.length > 1" @tab-click="tabClick" @tab-remove="tabRemove">
          <el-tab-pane v-for="item in tabs" :key="item.path" :label="item.displayName" :name="item.path" />
        </el-tabs>
      </div>

      <el-main>
        <div class="basic-content">
          <keep-alive :key="shouldRefresh" :include="keepAliveIncludes.map((item) => item.componentName)">
            <router-view />
          </keep-alive>
          <div v-show="isMicro" id="subapp"></div>
          <isLinkVue :links="links" :active="link"></isLinkVue>
        </div>
      </el-main>
    </el-container>
    <checkPasswordVue ref="checkPasswordRef"></checkPasswordVue>
    <changeThemeVue ref="changeThemeRef" />
    <!-- qa -->
    <div v-drag class="qa-icon" v-show="cachedQa[_path]" @click="showQaDrawer">
      <img :src="tips" alt="" />
    </div>
    <qaDrawerVue ref="qaDrawerRef"></qaDrawerVue>
  </el-container>
</template>

<style lang="scss" scoped>
.container {
  :deep(.el-aside) {
    position: relative;
    transition: width 0.3s;
    &:not(.home-collapse) {
      width: 200px !important;
    }
    &.home-collapse {
      width: 64px !important;
      #logo {
        width: 64px;
      }
    }
    // position: relative;
    #logo {
      position: sticky;
      top: 0;
      padding: 16px 0;
      width: 100%;
      height: 44px;
      // box-shadow: 0 1px #e6e6e6;
      background: #fff;
      box-sizing: content-box;
      transition: width 0.3s;
      z-index: 1;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .empty-menu {
      position: absolute;
      top: 50%;
      width: 100%;
      color: #999;
      text-align: center;
      transform: translateY(-50%);
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #e6e6e6;
    border-left: 1px solid #e6e6e6;
    .left,
    .right {
      .el-dropdown {
        padding: 0 16px;
        .el-icon-arrow-down {
          transition: transform 0.6s;
        }
        &:hover {
          cursor: pointer;
          .el-icon-arrow-down {
            transform: rotate(-180deg);
          }
        }
      }
      .el-dropdown:first-child {
        padding-left: 0;
      }
      .el-dropdown:not(:first-child) {
        position: relative;
        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0;
          top: 50%;
          width: 1px;
          height: 12px;
          background-color: #eee;
          transform: translateY(-50%);
        }
      }
    }
    .left {
      display: flex;
      align-items: center;
      .collapse {
        margin: 0;
        margin-right: 16px;
        font-size: 18px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;

      .notification {
        position: relative;
        padding: 0 16px;
        &:hover {
          cursor: pointer;
        }
        .icon-size {
          font-size: 18px;
        }
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          right: 0;
          top: 50%;
          width: 1px;
          height: 12px;
          background-color: #eee;
          transform: translateY(-50%);
        }
      }

      #qrI {
        padding: 0 16px;
        width: fit-content;
        &:hover {
          cursor: pointer;
          .qrCodeCard {
            visibility: visible;
            opacity: 1;
          }
        }
        .icon-phone-android {
          margin: 0;
        }
        .qrCodeCard {
          visibility: hidden;
          position: absolute;
          top: 46px;
          right: 50px;
          width: 430px;
          z-index: 9999;
          background-color: rgb(255, 255, 255);
          font-size: 16px;
          opacity: 0;
          cursor: auto;
          user-select: none;
          transition: all 0.3s;
          &:hover {
            visibility: visible;
            opacity: 1;
          }

          .el-card__body {
            padding: 0;
          }
          .qrCodeTopWord {
            text-align: center;
            margin: 10px 0 30px 0;
          }
          .qrCodeBox {
            width: 200px;
            height: 180px;
            margin: 0 auto;
          }

          .qrCodeBotWord {
            text-align: center;
            margin: 10px 0;
          }
        }
      }
    }
  }
  .tabs {
    :deep(.el-tabs__header) {
      margin: 4px 0;
      .el-tabs__nav-wrap.is-scrollable {
        .el-tabs__nav {
          border-radius: unset;
        }
      }
      .el-tabs__item {
        height: 30px;
        line-height: 30px;
        // &.is-active {
        //   border-bottom-color: #e4e7ed;
        // }
      }
      .el-tabs__nav-next,
      .el-tabs__nav-prev {
        width: 20px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-top: 1px solid #e4e7ed;
      }
    }
  }

  .el-main {
    padding: 16px;
    background-color: #eee;
    .basic-content {
      position: relative;
      padding: 16px;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: #fff;
      border-radius: 2px;
    }
  }

  .top {
    display: flex;
    align-items: center;
    .desc {
      margin: 0 5px;
      font-size: 14px;
    }
    .haveName {
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }

    .noName {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      color: #fff;
      line-height: 38px;
      text-align: center;
    }
  }

  .qa-icon {
    position: fixed;
    right: 66px;
    bottom: 66px;
    width: 80px;
    height: 80px;
    cursor: pointer;
    user-select: none;
    z-index: 999;
    img {
      -webkit-user-drag: none;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
.mb-dropdown {
  overflow: auto;
  max-height: 300px;
}
</style>
