import { request } from '@/api/index'
import { selectFile } from '@/utils/file.js'

/**
 * 上传文件
 * @param file
 * @param method
 * @param url
 * @param options
 * @returns {Promise}
 */
export function uploadFile({ file, method = 'post', url }) {
  return request({
    method,
    url,
    data: file,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export function queryCountries() {
  return request({
    method: 'get',
    url: '/core/area/countries',
    params: { paged: false }
  })
}

export function queryStates({ parentId }) {
  return request({
    method: 'get',
    url: '/core/area/states',
    params: { paged: false, parentId }
  })
}

export function queryCities({ parentId }) {
  return request({
    url: '/core/area/cities',
    method: 'get',
    params: { paged: false, parentId }
  })
}

export function queryRegion({ parentId }) {
  return request({
    url: '/core/area/regions',
    method: 'get',
    params: { paged: false, parentId }
  })
}

export function queryCurrentOrganization() {
  return request({
    method: 'get',
    url: '/core/employee/current/list',
    params: {}
  })
}

export function updateSwitchOrganization({ organizationId }) {
  return request({
    method: 'put',
    url: '/core/auth/organization',
    data: { organizationId }
  })
}

/**
 * 获取解决方案
 * @param type 枚举 PC/BM/CM
 * @returns {AxiosPromise}
 */
export function queryCurrentSolutionResource(type = 'PC') {
  return request({
    method: 'get',
    url: `/core/solution/current/${type}`
  })
}

export function queryCurrentAccount() {
  return request({
    method: 'get',
    url: '/core/account/current'
  })
}

export function queryEmployeeOrganization() {
  return request({
    method: 'get',
    url: '/core/employee/current/organization/list'
  })
}

/**
 * 新增或修改手机或邮箱
 * @param type 凭证类型
 * @param currentPassword 当前密码
 * @param iso_code 国家代码 ISO 3166-1 alpha-2 codes
 * @param cc 国家区码
 * @param newStubKey 手机 或者 邮箱
 * @param captcha otp 短信或者邮箱验证码
 * @returns {AxiosPromise}
 */
export function updatePhoneOrMail({ type, currentPassword, iso_code, cc, newStubKey, captcha }) {
  return request({
    url: `/core/account`,
    method: 'put',
    data: { type, currentPassword, iso_code, cc, newStubKey, captcha }
  })
}

export function updatePassword({ currentPassword, password, repeatPassword }) {
  return request({
    method: 'put',
    url: '/core/account/password',
    data: {
      currentPassword,
      password,
      repeatPassword
    }
  })
}

export function updatePerson({ birthday, gender, name, avatar }) {
  return request({
    method: 'put',
    url: '/core/account/personal',
    data: {
      birthday,
      gender,
      name,
      avatar
    }
  })
}

export function uploadAvatar() {
  return selectFile().then((elementFile) => {
    const file = new FormData()
    file.append('file', elementFile)
    return uploadFile({
      method: 'post',
      url: '/core/file/upload',
      file
    })
  })
}

export function updateForgetPassword({ repeatPassword, password, captcha, stubKey }) {
  return request({
    method: 'post',
    url: '/core/account/password/forget',
    data: {
      repeatPassword,
      password,
      captcha,
      stubKey
    }
  })
}

/**
 * 查询国家码
 * @returns {AxiosPromise}
 */
export function queryCC(language = 'en') {
  return request({
    url: `/core/area/cc`,
    method: 'get',
    headers: {
      'Accept-Language': language
    }
  })
}

/**
 * 切换国际化
 * @returns {AxiosPromise}
 */
export function switchI18n(lang) {
  return request({
    url: `/core/auth/language`,
    method: 'put',
    headers: {
      'Accept-Language': lang
    }
  })
}
