<script setup>
import { ref, computed } from 'vue'
import dayjs from 'dayjs'
import { nData } from './notification.setup'
import { visibleNotificationDialog, notificationShow, clickNotification, readAllNotification } from './notification.setup'
import { i18n } from '@/setup/i18n-setup'

const options = computed(() => [
  [
    {
      value: '',
      label: i18n.t('a8b0c20416853bda54120bf19477ad11')
    },
    {
      value: true,
      label: i18n.t('8ad629e52f51be63def4064c4d2ba0fc')
    },
    {
      value: false,
      label: i18n.t('894a101ee373f0e34f0047a004cbfefe')
    }
  ],
  [
    {
      value: '',
      label: i18n.t('a8b0c20416853bda54120bf19477ad11')
    },
    {
      value: 'BUSINESS_MESSAGE',
      label: i18n.t('55aba7bd0132f71c181ca97fc03c7774')
    },
    {
      value: 'SYSTEM_MESSAGE',
      label: i18n.t('058ebdf2884474ebff2977ba2f0e275e')
    }
  ]
])
const value = ref(['', ''])
const params = {
  read: '',
  notifyType: ''
}

const handleChange = (val, index) => {
  console.log(index, val)
  if (index === 0) {
    params.read = val
  } else {
    params.notifyType = val
  }
  notificationShow(params)
}

const handleClick = (notification) => {
  visibleNotificationDialog.value = false
  clickNotification(notification)
}
</script>

<template>
  <div class="notification-dialog">
    <div class="header">
      <mb-space>
        <el-select
          v-for="(o, ind) in options"
          :key="ind"
          v-model="value[ind]"
          :placeholder="i18n.t('708c9d6d2ad108ab2c560530810deae9')"
          @change="($event) => handleChange($event, ind)"
        >
          <el-option v-for="item in o" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-button type="plain" icon="el-icon-s-order" @click="readAllNotification">{{ i18n.t('1d1a68e1b77d4d85f39c4a9d1eebede7') }}</el-button>
      </mb-space>
    </div>
    <div class="notifications">
      <div class="li" v-for="(n, index) in nData" :key="index" @click="handleClick(n)">
        <div :class="['title', n.read ? '' : 'not-read']">{{ n.content }}</div>
        <div class="infos">
          <el-tag>{{ n.serviceName }}</el-tag>
          <div class="time">{{ dayjs(n.createTime).format('YYYY-MM-DD HH:mm:ss') }}</div>
        </div>
      </div>
      <el-empty v-if="!nData.length"></el-empty>
    </div>
    <div class="footer">
      <el-button
        type="text"
        @click="
          () => {
            visibleNotificationDialog = false
            $router.push('/notificationList')
          }
        "
        >{{ i18n.t('90ef7c485bd31fab681c6e9d9afd5be8') }} &gt;&gt;</el-button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notification-dialog {
  .notifications {
    overflow-y: auto;
    max-height: 300px;
    padding-right: 8px;
    .li {
      padding: 16px 0;
      border-bottom: 1px solid #eee;
      &:hover {
        cursor: pointer;
      }
      &:last-child {
        border: none;
      }
      .title {
        position: relative;
        margin-bottom: 8px;
        // font-size: 16px;
        &.not-read {
          padding-left: 8px;
          &::before {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 8px;
            height: 8px;
            background-color: var(--primary-color);
            border-radius: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .infos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .time {
          color: #9a9a9a;
        }
      }
    }
  }
  .footer {
    padding-top: 8px;
    text-align: right;
    border-top: 1px solid #eee;
  }
}
</style>
