// import * as XLSX from 'xlsx'

/**
 * 服务式选择文件
 * @returns {Promise<File>}
 */
export function selectFile(multi = false, accept) {
  return new Promise((resolve) => {
    const element = document.createElement('input')
    element.style.opacity = '0'
    element.style.position = 'absolute'
    element.style.left = '-9999px'
    element.style.top = '-9999px'
    element.type = 'file'
    if (accept) {
      element.setAttribute('accept', accept)
    }
    if (multi) {
      element.setAttribute('multiple', 'multiple')
    }
    document.body.append(element)
    element.click()
    element.onchange = (e) => {
      const elementFile = multi ? e.target.files : e.target.files[0]
      setTimeout(() => {
        document.body.removeChild(element)
      }, 16)
      resolve(elementFile)
    }
  })
}

/// 下载文件
export function downloadFile(file, fileName) {
  let blob = new Blob([file], {
    type: 'application/xlsx;charset=utf-8'
  })
  let eleLink = document.createElement('a')
  eleLink.download = fileName
  eleLink.style.display = 'none'
  eleLink.href = URL.createObjectURL(blob)
  document.body.appendChild(eleLink)
  eleLink.click()
  URL.revokeObjectURL(eleLink.href)
  document.body.removeChild(eleLink)
}

/// 下载excel
export async function file2Xce(file) {
  const XLSX = await import('xlsx')
  return new Promise(function (resolve) {
    const reader = new FileReader()
    reader.onload = function (e) {
      console.log('e: ', e)
      const data = e.target.result
      const wb = XLSX.read(data, {
        type: 'binary'
      })
      console.log('wb: ', wb)
      const result = []
      wb.SheetNames.forEach((sheetName) => {
        result.push({
          sheetName: sheetName,
          sheet: XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
        })
      })
      resolve(result)
    }
    reader.readAsBinaryString(file)
  })
}

// 下载文件v2
export function downloadFileV2(file) {
  const imgUrl = file.url // 图片链接
  const a = document.createElement('a')
  // 这里是将url转成blob地址，
  fetch(imgUrl) // 跨域时会报错
    .then((res) => res.blob())
    .then((blob) => {
      // 将链接地址字符内容转变成blob地址
      a.href = URL.createObjectURL(blob)
      a.download = file.name // 下载文件的名字
      document.body.appendChild(a)
      a.click()
      //在资源下载完成后 清除 占用的缓存资源
      window.URL.revokeObjectURL(a.href)
      document.body.removeChild(a)
    })
}
