// 按钮权限 v-auth
import { store } from '@/store/index'

// 如果找到则代表该按钮被禁用，找不到则代表该按钮没有被禁用
const hasAuth = (value) => {
  const { auth } = store.state
  const button = auth.buttonList.find((item) => item.code === value)
  if (button) return false
  return true
}

/**
 *
 * @param {HTMLElement} el
 */
const inserted = (el, { value }) => {
  const f = hasAuth(value)
  if (f === false) {
    const newAuth = document.createComment('v-auth')
    el.parentNode.replaceChild(newAuth, el)
  }
}

export default {
  name: 'auth',
  inserted
}
