import Axios from 'axios'
import { Message } from '@/setup/elementUI'
// import router from '@/router'
import { getToken, logout } from '@/utils/localStorage'
import { useLoading } from '@/utils/loading'

const cache = {}
window.cache = cache
export function has(key) {
  return !!cache[key]
}
export function get(key) {
  return cache[key]
}

export function set(key, value) {
  return (cache[key] = value)
}
const request = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  timeout: 50000,
  showLoading: true
})

const requestFile = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 50000,
  responseType: 'arraybuffer'
})

const { startLoading, closeLoading } = useLoading()

request.interceptors.request.use((req) => {
  // let key = req.url + req.method + (req.method === 'get' ? JSON.stringify(req.params) : JSON.stringify(req.data))
  // if (has(key)) {
  //   return get(key)
  // }
  if (req.showLoading) {
    startLoading()
  }
  const token = getToken()
  if (token) {
    req.headers.Authorization = token
  }
  return req
})

request.interceptors.response.use(
  (res) => {
    if (res.config.showLoading) {
      closeLoading()
    }
    if (res.status > 300 || res.status < 200) {
      Message.error(res.data.message || '网络出错了, 请稍候再试一次')
      return Promise.reject(res.data)
    }
    if (res.data.code !== 0) {
      Message.error(res.data.message || '系统出错了, 请稍候再试一次')
      return Promise.reject(res.data)
    }
    let key = res.config.url + res.config.method + (res.config.method === 'get' ? JSON.stringify(res.config.params) : JSON.stringify(res.config.data))
    set(key, res.data)
    return res.data
  },
  (err) => {
    console.log('err: ', err, err.response)
    if (err.config.showLoading) {
      closeLoading()
    }
    // 登录失效
    if (err.response?.status === 401) {
      Message.error('Login is invalid, please login again')
      // router.replace('/login')
      logout(true)
      return Promise.reject(err)
    }
    // console.error(err.response?.data)
    // Message.error(err.response?.data?.message || err.message || '服务器出错了, 请稍候再试一次')
    const errMsg = err.response?.data?.message || err.message || '服务器出错了, 请稍候再试一次'
    const traceId = err.response?.data?.traceId || err.traceId
    Message({
      type: 'error',
      dangerouslyUseHTMLString: true,
      message: traceId ? `<span>${errMsg}</span><br /><br /><span>traceId: ${traceId}</span>` : `<span>${errMsg}</span>`
    })
    return Promise.reject(err)
  }
)

requestFile.interceptors.request.use((req) => {
  const token = getToken()
  if (token) {
    req.headers.Authorization = token
  }
  startLoading()
  return req
})

requestFile.interceptors.response.use(
  (res) => {
    closeLoading()
    let fileName = '未命名文件'
    try {
      fileName = decodeURIComponent(res.headers['content-disposition'].split('=')[1])
    } catch (e) {
      console.log('e :', e)
    }
    return { file: res.data, fileName }
  },
  (error) => {
    closeLoading()
    return Promise.reject(error)
  }
)

export { request, requestFile }
