// import * as _mqtt from 'mqtt/dist/mqtt.esm'

class MqttTools {
  subscribeCached = {}
  initial = false
  constructor({ clientId }) {
    if (MqttTools.instance) return this
    this.clientId = clientId
    this.host = process.env.VUE_APP_MQTT
    this._importMqtt().then((mqtt) => {
      this.client = mqtt.connect(this.host, {
        keepalive: 60,
        clientId,
        protocolId: 'MQTT',
        protocolVersion: 4,
        clean: true,
        reconnectPeriod: 1000,
        connectTimeout: 30 * 1000,
        will: {
          topic: 'WillMsg',
          payload: 'Connection Closed abnormally..!',
          qos: 0,
          retain: false
        }
      })

      this.event()

      this.initial = true
    })

    MqttTools.instance = this
  }

  static instance = null

  async _importMqtt() {
    const mqtt = await import('mqtt/dist/mqtt.esm')
    // const mqtt = await import('chunk-mqtt')
    return mqtt.default
  }

  disconnect() {
    this.unsubscribe()
    this.client.end()
    MqttTools.instance = null
  }

  event() {
    this.client.on('error', (err) => {
      console.log('Connection error: ', err)
      this.client.end()
    })

    this.client.on('reconnect', () => {
      console.log('Reconnecting...')
    })

    this.client.on('connect', () => {
      console.log('Client connected:' + this.clientId)
    })

    // Received
    this.client.on('message', (topic, message) => {
      // console.log('Received Message: ' + message.toString() + '\nOn topic: ' + topic)
      if (this.subscribeCached[topic]) {
        this.subscribeCached[topic].call(this, topic, message.toString())
      }
    })
  }

  subscribe(topic, cb) {
    if (this.initial) {
      if (!this.subscribeCached[topic]) {
        this.subscribeCached[topic] = cb
        this.client.subscribe(topic, { qos: 0 })
        console.log(`subscribe topic: ${topic}`)
      }
    } else {
      setTimeout(() => {
        this.subscribe(topic, cb)
      }, 1000)
    }
  }

  unsubscribe() {
    if (this.subscribeCached) {
      const topic = Object.keys(this.subscribeCached).map((key) => key)
      this.client.unsubscribe(topic)
      console.log(`unsubscribe topic: ${topic}`)
      this.subscribeCached = {}
    }
  }
}

export default MqttTools
