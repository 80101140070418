<script setup>
import { ref, nextTick, watch } from 'vue'
import { getQaExistUriDetail } from '@/api/qa'
import { previewImageForApi } from '@/setup/previewImageForApi/index'

const visible = ref(false)
const data = ref({})

watch(visible, (val) => {
  if (!val) {
    const previewEle = document.querySelector('.previewImg')
    if (previewEle) {
      previewEle.parentNode.removeChild(previewEle)
    }
  }
})

const show = (uri) => {
  getQaExistUriDetail(uri).then((res) => {
    visible.value = true
    data.value = res.data
    nextTick(() => {
      const qaAnswerEle = document.querySelector('.qaAnswer')
      const imgs = qaAnswerEle.querySelectorAll('img')
      if (imgs.length > 0) {
        const srcList = [...imgs].map((item) => item.getAttribute('src'))
        qaAnswerEle.addEventListener('click', (e) => {
          if (e.target.tagName === 'IMG') {
            const src = e.target.getAttribute('src')
            previewImageForApi(
              srcList,
              srcList.findIndex((it) => it == src)
            )
          }
        })
      }
    })
  })
}

// eslint-disable-next-line no-undef
defineExpose({
  show
})
</script>

<template>
  <div class="qaDrawer" :key="Date.now()">
    <mb-drawer :value.sync="visible" size="middle" :title="data.question">
      <div class="qaAnswer" v-html="data.answer"></div>
    </mb-drawer>
  </div>
</template>
