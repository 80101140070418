<script setup>
import { getDomainTheme } from '@/utils/localStorage'
import { ref } from 'vue'
import { insertedThemeLink, themes } from './setup'
import { Loading } from '@/setup/elementUI'

const themeDialog = ref(false)
const activeName = ref(getDomainTheme().theme)

const handleChangeTheme = () => {
  const newTheme = themes.find((item) => item.key === activeName.value)
  const loadingInstance = Loading.service({
    lock: true,
    background: 'rgba(255,255,255, 0.7)'
  })
  insertedThemeLink(newTheme).finally(() => {
    loadingInstance.close()
    themeDialog.value = false
  })
}

const openDialog = () => {
  themeDialog.value = true
}

// eslint-disable-next-line no-undef
defineExpose({
  openDialog
})
</script>

<template>
  <!-- 更换主题弹出 -->
  <mb-dialog title="切换主题" v-model="themeDialog" @submit="handleChangeTheme">
    <div class="themes">
      <div :class="{ active: activeName === item.key, common: true, [item.themeName]: true }" v-for="(item, index) in themes" :key="index">
        <div class="theme" :style="{ background: item.color }" @click="activeName = item.key">{{ item.themeName }}</div>
      </div>
    </div>
  </mb-dialog>
</template>

<style lang="scss" scoped>
.themes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  .theme {
    position: relative;
    line-height: 120px;
    border-radius: 8px;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .common {
    padding: 2px;
    border: 1.5px dashed transparent;
  }
  .active {
    padding: 2px;
    border: 1.5px dashed;
    border-radius: 8px;
    &.red {
      border-color: red;
    }
    &.black {
      border-color: black;
    }
    &.blue {
      border-color: blue;
    }
  }
}
</style>
