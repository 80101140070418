import { Loading } from '@/setup/elementUI'

let loading = null
// 使用count可能出现-1的情况
// let count = 0
const queue = []
window._queue = queue
export function useLoading() {
  // 当传入 unique 的时候，closeLoading 也必须传入一样的 unique ，否则loading不会关闭
  function startLoading(params = { message: 'Loading...', unique: 'unique' }) {
    const { message, unique } = params
    // count++
    queue.push(unique || 1)
    if (loading) return
    loading = Loading.service({
      lock: true,
      text: message || 'Loading...',
      background: 'rgba(255,255,255, 0.7)'
    })
  }

  function closeLoading(unique = 'unique') {
    // count--
    // if (count <= 0 && loading) {
    //   loading.close()
    //   count = 0
    //   loading = null
    // }

    const index = queue.findIndex((u) => u === unique)
    if (index !== -1) {
      queue.splice(index, 1)
    }
    if (queue.length === 0) {
      loading?.close()
      loading = null
    }
  }

  return { startLoading, closeLoading }
}
