export const routeMap = {
  basic_app: () => import(/* webpackChunkName: "basic_listProject" */ '@/views/basic/basic_app/index.vue'),
  basic_project: () => import(/* webpackChunkName: "basic_listProject" */ '@/views/basic/basic_project/index.vue'),
  basic_solution: () => import(/* webpackChunkName: "basic_solution" */ '@/views/basic/basic_solution/index.vue'),
  basic_resource: () => import(/* webpackChunkName: "basic_resource" */ '@/views/basic/basic_resource/index.vue'),
  basic_domainTheme: () => import(/* webpackChunkName: "basic_listProject" */ '@/views/basic/basic_domainTheme/index.vue'),
  basic_organizationManage: () => import(/* webpackChunkName: "basic_organizationManage" */ '@/views/basic/basic_organizationManage/index.vue'),
  basic_payLog: () => import(/* webpackChunkName: "basic_payLog" */ '@/views/basic/basic_payLog/index.vue'),
  basic_offlinePay: () => import(/* webpackChunkName: "basic_offlinePay" */ '@/views/basic/basic_offlinePay/index.vue')
}
