// 全局mixins，方便调用国际化
export default {
  methods: {
    // 如果没有匹配的，原样输出
    $tranResult(result, r) {
      if (result.includes(r)) {
        return result.replace(r, '')
      }
      return result
    },
    // 国际化，公共的
    $commonT(key) {
      let result = this.$t(`common.${key}`)
      return this.$tranResult(result, 'common.')
    },
    // 国际化，按路由
    $mbT(key) {
      const routeName = this.$route.name
      let result = this.$t(`${routeName ? routeName + '.' : ''}${key}`)
      return this.$tranResult(result, `${routeName}.`)
    },
    // cb,要使用的翻译类型
    $mb(key, cb = 't') {
      const tMap = {
        t: (key) => this.$t(key),
        mbT: this.$mbT,
        commonT: this.$commonT
      }
      return tMap[cb || 't'](key)
    }
  }
}
