// https://kazupon.github.io/vue-i18n/zh/guide/lazy-loading.html
//i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 组件库国际化
import mbZh from '@mybeauty/basic-bpc-component/lang/zh-CN'
// element-ui国际化
import elementZh from 'element-ui/lib/locale/lang/zh-CN'
import axios from 'axios'
import zhCN from '@/locales/lang/zh-CN' // 加载默认本地化语言
// import ElementLocale from 'element-ui/lib/locale'

// console.log(ElementLocale, 'ElementLocale')
// // eslint-disable-next-line no-undef
// console.log(ELEMENT, 'ELEMNT----')

Vue.use(VueI18n)

// 默认本地化语言
const locale = 'zh-CN'
export const i18n = new VueI18n({
  locale, // 设置语言环境
  fallbackLocale: locale,
  messages: {
    [locale]: { ...mbZh, ...elementZh, ...zhCN }
  }, // 设置语言环境信息
  silentFallbackWarn: true,
  silentTranslationWarn: true
})

// ElementLocale.i18n((key, value) => i18n.t(key, value))

// eslint-disable-next-line no-undef
ELEMENT.i18n((key, value) => i18n.t(key, value))

const loadedLanguages = [] // 我们的预装默认语言

function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

// 按需加载
export function loadLanguageAsync(lang) {
  lang = lang.replace('_', '-')
  // if (lang === 'zh-CN') Promise.resolve(setI18nLanguage(lang))
  // 如果语言相同
  // if (i18n.locale === lang) {
  //   return Promise.resolve(setI18nLanguage(lang))
  // }

  // 如果语言已经加载
  // if (loadedLanguages.includes(lang)) {
  //   return Promise.resolve(setI18nLanguage(lang))
  // }

  // 如果尚未加载语言
  return Promise.all([
    process.env['VUE_APP_ENV'] === 'local'
      ? import(`@/locales/lang/${lang}.json`)
      : axios({
          url: `https://${process.env.VUE_APP_BUCKET}.cos.${process.env.VUE_APP_REGION}.myqcloud.com/basic-bpc-webapp/${process.env['VUE_APP_ENV']}/${lang}.json?t=${Date.now()}`
        }),
    import(/* webpackChunkName: "lang-[request]" */ `@/locales/other/${lang}.js`)
  ]).then((res) => {
    const [localLang, otherLang] = res
    console.log(localLang, 'localLanglocalLang')
    i18n.setLocaleMessage(lang, {
      ...(localLang?.data || localLang?.default),
      ...otherLang?.default
    })
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

Vue.prototype.$loadLanguageAsync = loadLanguageAsync
