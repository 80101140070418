<script>
export default {
  name: 'ml-menu-item'
}
</script>
<script setup>
/*global defineProps*/

defineProps({
  menu: {
    type: Object,
    default: () => {}
  }
})
</script>

<template>
  <div class="menu-box">
    <el-submenu v-if="menu.type === 'FOLDER' && menu.children?.length" :index="menu.id + ''">
      <template slot="title">
        <i :class="['iconfont', menu.icon]"></i>
        <span>{{ menu.displayName }}</span>
      </template>

      <ml-menu-item v-for="(item, index) of menu.children" :key="index" :menu="item"></ml-menu-item>
    </el-submenu>
    <el-menu-item v-if="menu.type === 'MENU'" :index="menu.path" :key="menu.path">
      <i :class="['iconfont', menu.icon]"></i>
      <span slot="title">{{ menu.displayName }}</span>
    </el-menu-item>
  </div>
</template>

<style lang="scss" scoped></style>
