import Vue from 'vue'
import PreviewImageForApiVue from './previewImageForApi'

export const previewImageForApi = (srcList = [], initIndex = 0) => {
  if (!Array.isArray(srcList)) {
    throw new Error('请传入数组')
  } else if (srcList.length === 0) {
    console.warn('数组为空')
    return
  }
  const previewImgEle = document.querySelector('.preview-image-for-api') || document.createElement('div')
  previewImgEle.className = 'preview-image-for-api'
  document.body.appendChild(previewImgEle)
  const Preview = Vue.extend(PreviewImageForApiVue)
  const previewApp = new Preview({
    propsData: {
      src: srcList[initIndex],
      srcList: srcList
    }
  }).$mount(previewImgEle)
  previewApp.$refs.elImageRef.showViewer = true
}
