import { queryDomainThemeByDomain } from '@/api/basic/domainTheme'
import { getDomainTheme, setDomainTheme } from '@/utils/localStorage'

const themes = [
  {
    themeName: 'red',
    color: 'red',
    key: 'mb-red'
  },
  {
    themeName: 'black',
    color: '#000',
    key: 'mb-black'
  },
  {
    themeName: 'blue',
    color: 'blue',
    key: 'mb-blue'
  },
  {
    themeName: 'green',
    color: '#0CC2B7',
    key: 'mb-green'
  },
  {
    themeName: 'el-blue',
    color: '#409EFF',
    key: 'mb-el-blue'
  },
  {
    themeName: 'orange',
    color: '#FF9670',
    key: 'mb-orange'
  },
  {
    themeName: 'blossom',
    color: '#FF889F',
    key: 'mb-blossom'
  }
]

// 创建link/script元素
const createEle = (href, target = 'link') => {
  const ele = document.createElement(target)
  if (target === 'link') {
    ele.rel = 'stylesheet'
    ele.href = href
  } else if (target === 'script') {
    ele.src = href
  }
  return ele
}

/**
 *
 * @param {Function} fn
 */
const createPromise = (fn) => {
  if (!fn || typeof fn !== 'function') {
    throw new Error('fn 不是函数')
  }
  return new Promise((resolve, reject) => {
    fn(resolve, reject)
  })
}

/**
 * 16进制转rgba
 * @param {string} color
 * @param {Float32Array} color
 * @returns
 */
const hex2rgba = (color, opacity = 1) => {
  //
  if (color.includes('rgb')) return color
  color = color.replace('#', '').trim()
  if (color.length === 3) {
    color = color
      .split('')
      .map((item) => item.padStart(2, item))
      .join('')
  }
  const rgbaColor = `rgba(${parseInt(color.substring(0, 2), 16)}, ${parseInt(color.substring(2, 4), 16)}, ${parseInt(color.substring(4), 16)}, ${opacity})`
  return rgbaColor
}

const setCssRootVar = () => {
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color')
  if (!primaryColor.includes('#') && !primaryColor.includes('rgb')) {
    // throw new Error('主题色需要使用16进制或rgb格式')
    console.error('主题色需要使用16进制或rgb格式')
    return
  }
  const cssVar = {
    '--primary-hover-color': hex2rgba(primaryColor, 0.4),
    '--primary-disabled-color': hex2rgba(primaryColor, 0.7)
  }
  Object.keys(cssVar).forEach((key) => {
    document.querySelector(':root').style.setProperty(key, cssVar[key])
  })
}

// 加载ali的iconfont
const loadIconfont = (domainTheme) => {
  if (document.querySelector(`link[href="${domainTheme.iconcss}"]`) || document.querySelector(`script[href="${domainTheme.iconjs}"]`)) {
    return
  }
  //iconfont
  const iconLink = createEle(domainTheme.iconcss)
  // iconfont的script
  const iconScript = createEle(domainTheme.iconjs, 'script')
  document.head.appendChild(iconLink)
  document.head.appendChild(iconScript)
}

const insertedThemeLink = async (theme) => {
  let domainTheme = getDomainTheme()
  if (!domainTheme) {
    // 请求主题域名
    await queryDomainThemeByDomain().then((res) => {
      try {
        domainTheme = JSON.parse(res.data.config)
        setDomainTheme(domainTheme)
      } catch (e) {
        console.log('e :', e)
      }
    })
  }

  const defaultTheme = themes.find((item) => item.key === domainTheme?.theme)
  theme = theme ?? defaultTheme

  loadIconfont(domainTheme)
  // 默认主题色已经打包进项目了，不需要重新加载
  // if (theme.key === 'mb-red') {
  //   themes.forEach((item) => {
  //     if (item.key !== theme.key) {
  //       const oldTheme = document.getElementById(item.key)
  //       oldTheme && document.head.removeChild(oldTheme)
  //     }
  //   })
  //   return Promise.resolve()
  // }
  // 如果主题一样，则不重复操作
  if (theme && document.getElementById(theme.key)) {
    return Promise.resolve()
  }
  const loadTheme = () => {
    const bucket = process.env.VUE_APP_BUCKET
    const region = process.env.VUE_APP_REGION
    // 主题色
    const themeLink = createEle(`https://${bucket}.cos.${region}.myqcloud.com/themes/${theme.key}/index.css.gz`)
    themeLink.id = theme.key
    document.head.appendChild(themeLink)
    return createPromise((resolve, _) => {
      // 加载成功之后再删除之前的主题
      themeLink.onload = () => {
        themes.forEach((item) => {
          if (item.key !== theme.key) {
            const oldTheme = document.getElementById(item.key)
            oldTheme && document.head.removeChild(oldTheme)
          }
        })
        if (domainTheme && domainTheme.key !== theme.key) {
          domainTheme.theme = theme.key
          setDomainTheme(domainTheme)
        }
        // 加载完了再获取
        setCssRootVar()
        resolve()
      }
      // 加载失败则删除当前的主题
      themeLink.onerror = () => {
        document.head.removeChild(themeLink)
        // reject(`主题色css加载失败${themeLink.href}`)
        setTimeout(() => {
          resolve(loadTheme())
        }, 1000)
      }
    })
  }

  return loadTheme()
}

export { insertedThemeLink, themes }
