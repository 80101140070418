<script setup>
import MlMenuItem from '@/components/menu/ml-menu-item'
import { store } from '@/store/index.js'
import { computed } from 'vue'

// eslint-disable-next-line no-undef
defineProps({
  collapse: {
    type: Boolean,
    default: false
  }
})

const menus = computed(() => store.state.menus)
</script>

<template>
  <el-menu :collapse="collapse" :class="collapse ? 'is-collapse' : ''" :default-active="$route.path" router>
    <ml-menu-item v-for="item of menus" :key="item.code" :menu="item"></ml-menu-item>
  </el-menu>
</template>

<style lang="scss" scoped>
.el-menu {
  border-right: none;
}
.is-collapse,
.el-menu--collapse {
  ::v-deep {
    .menu-box {
      .el-submenu__title {
        text-align: center;
        & + ul {
          display: none;
        }
        .iconfont {
          margin: 0;
        }
        > span,
        .el-submenu__icon-arrow {
          display: none;
        }
      }
    }
  }
}
</style>
