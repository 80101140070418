function initGoogleMap() {
  console.log('initGoogleMap')
}

window.initGoogleMap = initGoogleMap

// 加载谷歌地图所需的js脚本
export const loadGoogleMapJs = () => {
  return new Promise((resolve, reject) => {
    const key = 'AIzaSyDjlUXkZkPZtuGiORInLeu5_zDzqa5P29Y'
    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initGoogleMap&libraries=places&v=weekly`
    script.defer = true
    script.async = true
    document.body.appendChild(script)
    script.onerror = (err) => {
      reject(err)
    }
    script.onload = () => {
      resolve()
    }
  })
}
