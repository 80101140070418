import { request } from '@/api/index.js'
import axios from 'axios'

/**
 * 登录注册
 * @param loginKey 登录key 手机or邮箱
 * @param type 类型
 * @param scope 范围类型
 * @param cc 国家区号
 * @param iso_code 国家代号
 * @param loginValue 登录key 密码or验证码
 * @returns {AxiosPromise}
 */
export function createLogin({ loginKey, type, scope = 'BUSINESS', cc, iso_code, loginValue, language = 'en' }) {
  return request({
    url: `/core/auth/login`,
    method: 'post',
    data: { loginKey, type, scope, cc, iso_code, loginValue, clientId: 'core', device: 'PC' },
    headers: {
      'Accept-Language': language
    }
  })
}

export function getCaptcha(language = 'en') {
  return axios
    .request({
      method: 'get',
      url: process.env['VUE_APP_BASE_URL'] + '/core/auth/captcha',
      responseType: 'arraybuffer',
      headers: {
        'Accept-Language': language
      }
    })
    .then((res) => {
      const token = res.headers.token
      const url = 'data:image/png;base64,' + btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
      return { token, url }
    })
}

/**
 * 发送验证码
 * @param loginKey 登录key 手机or邮箱
 * @param cc 国家区码
 * @param code 国家代码
 * @param captchaToken 图片验证码token
 * @param captchaCode 图片验证码
 * @returns {AxiosPromise}
 */
export function postMessage({ loginKey, cc, code, captchaToken, captchaCode }) {
  return request({
    url: `/core/auth/captcha`,
    method: 'post',
    data: { loginKey, cc, code, captchaToken, captchaCode }
  })
}

/**
 * 查询验证码
 * @param content
 * @returns {Promise}
 */
export function queryMessageCode({ content }) {
  return request({
    url: '/core/auth/magic/captcha',
    method: 'get',
    params: {
      content
    }
  })
}

/**
 * 设置密码
 * @param repeatPassword
 * @param password
 * @returns {Promise}
 */
export function postSetPassword({ repeatPassword, password }) {
  return request({
    method: 'post',
    url: '/core/account/password',
    data: { repeatPassword, password }
  })
}

/**
 * 注销，退出登录
 * @returns {AxiosPromise}
 */
export function logout() {
  return request({
    url: `/core/auth/logout`,
    method: 'post'
  })
}
