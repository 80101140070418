import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import { store } from './store'
import 'normalize.css'
import './styles/index.scss'
import './directives'
import dayjs from 'dayjs'
// 国际化
import { i18n } from '@/setup/i18n-setup'
import globalMixin from '@/mixins/globalMixin'
import PubSub from 'pubsub-js'
import BasicBpcComponent from '@mybeauty/basic-bpc-component'
import '@mybeauty/basic-bpc-component/BasicBpcComponent.css'
import { insertedThemeLink } from './views/home/setup'
import { loadGoogleMapJs } from '@/utils/googleMap'
import { Loading, MessageBox, Message, Notification } from '@/setup/elementUI'

if (process.env.isVite) {
  const ElementUI = await import('element-ui')
  Vue.use(ElementUI)
}

loadGoogleMapJs()
Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs
Vue.prototype.$pubsub = PubSub

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

Vue.prototype.$ELEMENT = {
  i18n: (key, value) => i18n.t(key, value),
  size: 'small',
  zIndex: 2000
}

Vue.use(BasicBpcComponent, {
  mbI18n: (key, value) => i18n.t(key, value),
  getI18n: () => i18n
})

Vue.mixin(globalMixin)

insertedThemeLink().finally(() => {
  new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')
})

// https://texteditor.com/font-converter/
console.info(
  `
%c
𝑾𝒆𝒍𝒄𝒐𝒎𝒆 𝒕𝒐 𝑴𝒚 𝑩𝒆𝒂𝒖𝒕𝒚
`,
  'color: #ff889f; font-size: 32px;'
)
