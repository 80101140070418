import { request } from '@/api/index'

/**
 * Q&A管理列表
 * @param keyword 关键词
 * @param terminal 终端 BPC :BPC BM :BM CM :CM {"BPC":"BPC","BM":"BM","CM":"CM"}
 * @param pageNum 当前页码，默认情况下为第一页，页码从1开始
 * @param pageSize 每页的记录数，默认情况下每页十条记录
 * @param paged 是否分页 默认为true
 * @param orderStr 格式为：{name1:direction,name2:direction,...}，例如name:desc,age:asc,createTime
 * @returns {Promise<any>}
 */
export const getQa = ({ keyword, terminal, pageNum, pageSize, paged, orderStr }) => {
  return request({
    url: `/core/v1/qa`,
    method: 'GET',
    params: {
      keyword,
      terminal,
      pageNum,
      pageSize,
      paged,
      orderStr
    }
  })
}

/**
 * 删除Q&A
 * @param qaId Q&A ID
 * @returns {Promise<any>}
 */
export const deleteQaId = ({ qaId }) => {
  return request({
    url: `/core/v1/qa/${qaId}`,
    method: 'DELETE'
  })
}

/**
 * 保存Q&A
 * @param question 问题标题
 * @param terminal 问题终端 {"BPC":"BPC","BM":"BM","CM":"CM"}
 * @param tags 问题标签
 * @param display 是否显示
 * @param sorts 排序
 * @param answer 问题解答
 * @param route 关联路由
 * @returns {Promise<any>}
 */
export const postQa = ({ question, terminal, tags, display, sorts, answer, route, summary }) => {
  return request({
    url: `/core/v1/qa`,
    method: 'POST',

    data: {
      question,
      terminal,
      tags,
      display,
      sorts,
      answer,
      route,
      summary
    }
  })
}

/**
 * 更新Q&A
 * @param qaId Q&A ID
 * @param question 问题标题
 * @param terminal 问题终端 {"BPC":"BPC","BM":"BM","CM":"CM"}
 * @param tags 问题标签
 * @param display 是否显示
 * @param sorts 排序
 * @param answer 问题解答
 * @param route 关联路由
 * @returns {Promise<any>}
 */
export const putQaId = ({ qaId, question, terminal, tags, display, sorts, answer, route, summary }) => {
  return request({
    url: `/core/v1/qa/${qaId}`,
    method: 'PUT',

    data: {
      question,
      terminal,
      tags,
      display,
      sorts,
      answer,
      route,
      summary
    }
  })
}

/**
 * 根据路径判断是否有Q&A详情
 * @param uri 路径
 * @returns {Promise<any>}
 */
export const getQaExistUri = (uri, terminal = 'BPC') => {
  return request({
    url: `/core/v1/qa/exist_uri`,
    method: 'GET',
    params: {
      uri,
      terminal
    },
    showLoading: false
  })
}

/**
 * 根据路径获取Q&A详情
 * @param uri 路径
 * @returns {Promise<any>}
 */
export const getQaExistUriDetail = (uri, terminal = 'BPC') => {
  return request({
    url: `/core/v1/qa/uri`,
    method: 'GET',
    params: {
      uri,
      terminal
    }
  })
}
