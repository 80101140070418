import { request } from '@/api'

export function queryDomainThemePage({ domain, pageNum, pageSize, paged, sort, orderStr }) {
  return request({
    url: '/core/host_theme',
    method: 'get',
    params: { domain, pageNum, pageSize, paged, sort, orderStr }
  })
}

/**
 *
 * @param domain 域名
 * @param config 配置项
 * @param remark
 * @returns {Promise}
 */
export function createDomainTheme({ domain, config, remark }) {
  return request({
    url: `/core/host_theme`,
    method: 'post',
    data: { domain, config, remark }
  })
}

/**
 *
 * @param hostThemeId 域名主题表 ID
 * @returns {Promise}
 */
export function queryDomainThemeById({ hostThemeId }) {
  return request({
    url: `/core/host_theme/${hostThemeId}`,
    method: 'get'
  })
}

/**
 *
 * @param hostThemeId
 * @param domain 域名
 * @param config 配置项
 * @param remark
 * @returns {Promise}
 */
export function updateDomainThemeById({ hostThemeId, domain, config, remark }) {
  return request({
    url: `/core/host_theme/${hostThemeId}`,
    method: 'put',
    data: { domain, config, remark }
  })
}

/**
 *
 * @param hostThemeId 域名主题表 ID
 * @returns {Promise}
 */
export function removeDomainThemeById({ hostThemeId }) {
  return request({
    url: `/core/host_theme/${hostThemeId}`,
    method: 'delete'
  })
}

/**
 *
 * @returns {Promise}
 */
export function queryDomainThemeByDomain(language = 'en', domain) {
  return request({
    method: 'get',
    url: '/core/host_theme/host',
    headers: {
      'Accept-Language': language
    },
    params: {
      domain
    }
  })
}
