// https://juejin.cn/post/6844903886004682765
// 常用的正则校验

// 邮箱
function isEmail(email) {
  const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  return reg.test(email)
}

// 手机号
function isPhone(phone) {
  const reg = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/
  return reg.test(phone)
}

// 纯数字
function isNumber(phone) {
  const reg = /^-?\d+$/
  return reg.test(phone)
}

// 小数
function isDecimal(phone) {
  const reg = /-?^\d+\.\d+$/
  return reg.test(phone)
}

// 字符
function isChart(phone) {
  const reg = /^[a-zA-Z0-9]+$/
  return reg.test(phone)
}

// emoji表情
export const isEmoji = (str) => {
  const reg = /[\u{1F601}-\u{1F64F}\u{2702}-\u{27B0}\u{1F680}-\u{1F6C0}\u{1F170}-\u{1F251}\u{1F600}-\u{1F636}\u{1F681}-\u{1F6C5}\u{1F30D}-\u{1F567}]/gmu
  return reg.test(str)
}

export const generalReg = {
  isEmail,
  isPhone,
  isNumber,
  isDecimal,
  isChart,
  isEmoji
}
