import router from '@/router/index'
import { logout as logoutApi } from '@/api/login/login'
import { resetStore } from '@/store/index'
import { disconnect } from '@/views/home/notification.setup'

// 解决方案
const solutionsKey = 'solutions'
const currentSolutionKey = 'currentSolution'
// 登录页记住我
const rememberKey = '_REMEMBER'
// 前端子应用的key
const projectListKey = 'projectList'
// 域名主题
const domainThemeKey = 'domainTheme'
// token
const tokenKey = 'token'
// 用户信息
const userInfoKey = 'userInfo'
// 用户当前点击的路由
const curRouteKey = 'curRoute'

let cached = null

// 获取全部的localStorage中的key
function getStorageKeys(update = false) {
  if (update) {
    cached = Object.keys(localStorage)
  }
  if (!cached) {
    cached = Object.keys(localStorage)
  }
  return cached
}

/**
 * 删除localStorage中的某个key
 * @param key
 * @param update 是否更新缓存中的key
 */
export function removeStorage(key, update = false) {
  if (getStorageKeys(update).includes(key)) {
    localStorage.removeItem(key)
  }
}

// 解决方案
export const setSolutions = (val) => localStorage.setItem(solutionsKey, JSON.stringify(val))
export const getSolutions = () => JSON.parse(localStorage.getItem(solutionsKey) || '[]')

// 当前用户选择的解决方案
export const setCurrentSolution = (val) => localStorage.setItem(currentSolutionKey, JSON.stringify(val))
export const getCurrentSolution = () => JSON.parse(localStorage.getItem(currentSolutionKey) || null)

// 当前用户点击的路由
export const setCurRoute = (val) => localStorage.setItem(curRouteKey, val)
export const getCurRoute = () => localStorage.getItem(curRouteKey)

// 前端子应用
export const setProjectList = (val) => localStorage.setItem(projectListKey, JSON.stringify(val))
export const getProjectList = () => JSON.parse(localStorage.getItem(projectListKey) || '[]')

// 记住我
export const setRememberMe = (val) => localStorage.setItem(rememberKey, val)
export const getRememberMe = () => localStorage.getItem(rememberKey)

// 域名主题
export const setDomainTheme = (val) => localStorage.setItem(domainThemeKey, JSON.stringify(val))
export const getDomainTheme = () => JSON.parse(localStorage.getItem(domainThemeKey))

// token
export const setToken = (val) => localStorage.setItem(tokenKey, val)
export const getToken = () => localStorage.getItem(tokenKey)

// 用户信息
export const setUserInfo = (val) => localStorage.setItem(userInfoKey, JSON.stringify(val))
export const getUserInfo = () => JSON.parse(localStorage.getItem(userInfoKey) || null)

// 登录退出时清空本地存储
export function logout(isLogout) {
  disconnect()
  if (isLogout === true) {
    out()
    return
  }
  return logoutApi().then(() => {
    out()
  })
}

const out = () => {
  const noClearKeys = [rememberKey]
  getStorageKeys(true).forEach((key) => {
    if (!noClearKeys.includes(key)) {
      localStorage.removeItem(key)
    }
  })
  router.replace('login')
  resetStore()
}

export { rememberKey }
