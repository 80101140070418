import router, { resetRouter } from '@/router/index.js'
import { routeMap } from '@/router/routeMap.js'
import Home from '@/views/home/Home.vue'
import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'
import { getCurrentSolution, getProjectList, getSolutions, getUserInfo, setCurrentSolution as _setCurrentSolution } from '@/utils/localStorage'
import { registerMicro } from '@/microApp/index'

Vue.use(Vuex)

const createStore = () => {
  return {
    state: {
      routes: [],
      menus: [],
      microApps: [], // 激活app的路由
      currentSolution: {},
      keepAliveIncludes: [],
      microInstance: {} // qiankun实例
    },
    mutations: {
      SET_KEEP_ALIVE_INCLUDES: (state, payload) => {
        state.keepAliveIncludes = payload || []
      },
      resetState(state) {
        state.menus = []
        state.routes = []
      },
      resetMicroApps(state) {
        state.microApps = []
      },
      setMicroApps(state, payload) {
        state.microApps = payload
      },
      setMicroInstance(state, payload) {
        state.microInstance = payload
      },
      setCurrentSolution(state, payload) {
        const solutions = getSolutions()
        const currentSolution = solutions.find((item) => item.id === payload)
        if (currentSolution) {
          state.currentSolution = currentSolution
        } else {
          state.currentSolution = solutions.length ? solutions[0] : { resourceList: [] }
        }
        // 设置按钮权限列表(弃用，后端没有在 hiddenButtonList 返回字段返回)
        // if (state.currentSolution && 'hiddenButtonList' in state.currentSolution) {
        //   store.commit('auth/SET_BUTTON_LIST', state.currentSolution.hiddenButtonList)
        // }
        _setCurrentSolution(store.state.currentSolution)
      },
      // 有组织的时候才注册
      registerRouter(state) {
        const { currentOrganizationId } = getUserInfo()
        if (currentOrganizationId) {
          const solutions = getSolutions()
          const projectList = getProjectList()
          // 注册子应用
          registerMicro(projectList)
          const cur = getCurrentSolution()
          store.commit('setCurrentSolution', cur?.id || solutions[0]?.id)
          store.commit('registerMenus')
          const menuOfRoute = getMenuOfRoute([state.currentSolution], projectList)
          state.routes = menuOfRoute
          // 每次注册时都重新reset一下router
          resetRouter()
          router.addRoute({
            path: '/',
            component: Home,
            children: [...menuOfRoute]
          })
        }
      },
      registerMenus(state) {
        const projectList = getProjectList()
        getMenuOfRoute([state.currentSolution], projectList)
        state.menus = filterMenu(state.currentSolution.resourceList)
        localStorage.setItem(
          'currentMenus',
          JSON.stringify(state.menus, (k, v) => {
            if (k === 'parentNode') return undefined
            return v
          })
        )
      }
    },
    actions: {
      // 所有子应用卸载, 修复子应用 router redirect 会影响父应用路由的bug
      async unmountMicro() {
        const ps = Promise.all(
          Reflect.ownKeys(store.state.microInstance)
            .map((key) => {
              if (store.state.microInstance[key]?.getStatus?.() === 'MOUNTED') {
                return store.state.microInstance[key]?.unmount?.()
              }
            })
            .filter(Boolean)
        )
        return ps
      }
    },
    modules: {
      user,
      auth
    }
  }
}

function getMenuOfRoute(solutions, projects) {
  let result = []

  solutions.forEach((item) => {
    item.resourceList.forEach((resource) => {
      re(resource)
    })
  })

  function re(resource) {
    if (resource.display === false) return
    if (resource.type === 'MENU') {
      resource.component = routeMap[resource.code]
      // 删除菜单下面的全部children
      if (resource.children?.length) {
        resource.$children = resource.children
        resource.children = []
      }
      const basePath = projects.find((item) => item.applicationId === resource.applicationId).appCode
      resource.path = `/${basePath}/${resource.code}`
      result.push(resource)
    } else {
      for (const child of resource.children) {
        re(child)
      }
    }
  }

  return result
}

// 过滤菜单 display 为 false 的资源
const filterMenu = (resourceList) => {
  const results = []
  const hiddenButtonList = []
  const re = (resource) => {
    // 按钮资源
    if (resource.type === 'BUTTON') {
      hiddenButtonList.push(resource)
    } else if (resource.$children?.length) {
      hiddenButtonList.push(...resource.$children.filter((item) => item.type === 'BUTTON'))
    }
    // 处理资源
    if (resource.parentNode) {
      const { children } = resource.parentNode
      resource.parentNode.children = children.filter((item) => item.display !== false)
    }
    if (resource.children?.length) {
      resource.children.forEach((item) => {
        item.parentNode = resource
        re(item)
      })
    }
  }

  resourceList.forEach((resource) => {
    if (resource.display !== false) {
      results.push(resource)
      re(resource)
    }
  })
  // 手动提交
  store.commit('auth/SET_BUTTON_LIST', hiddenButtonList)
  return results
}

export const store = new Vuex.Store(createStore())

// 重置store
export async function resetStore() {
  await store.dispatch('unmountMicro')
  store.replaceState(new Vuex.Store(createStore()).state)
}
