import { request } from '@/api'

/**
 * 获取站内信

 * @returns {Promise<any>}
 */
export const getNotificationBox = () => {
  return request({
    url: `/middleware/v1/inner_message/box`,
    method: 'GET'
  })
}

/**
 * 获取站内信列表
 * @param read
 * @param title
 * @param notifyType
 * @param pageNum 当前页码，默认情况下为第一页，页码从1开始
 * @param pageSize 每页的记录数，默认情况下每页十条记录
 * @param paged 是否分页 默认为true
 * @param orderStr 格式为：{name1:direction,name2:direction,...}，例如name:desc,age:asc,createTime
 * @returns {Promise<any>}
 */
export const getNotificationInnerMessage = ({ read, title, notifyType, pageNum, pageSize, paged, orderStr }) => {
  return request({
    url: `/middleware/v1/inner_message`,
    method: 'GET',
    params: {
      read,
      title,
      notifyType,
      pageNum,
      pageSize,
      paged,
      orderStr
    }
  })
}

/**
 * 已读
 * @param msgId 消息ID
 * @returns {Promise<any>}
 */
export const postNotificationRead = ({ msgId }) => {
  return request({
    url: `/middleware/v1/inner_message/${msgId}/read`,
    method: 'POST',
    showLoading: false
  })
}

/**
 * 全部已读

 * @returns {Promise<any>}
 */
export const postNotificationReadAll = () => {
  return request({
    url: `/middleware/v1/inner_message/readAll`,
    method: 'POST'
  })
}

/**
 * 批量已读
 * @param dto
 * @returns {Promise<any>}
 */
export const postBatchRead = ({ dto }) => {
  return request({
    url: `/middleware/v1/inner_message/read`,
    method: 'POST',
    data: dto
    // params: {
    //   dto
    // }
  })
}

/**
 * 获取推送渠道

 * @returns {Promise<any>}
 */
export const getNotificationChannel = () => {
  return request({
    url: `/middleware/v1/inner_message/channel`,
    method: 'GET'
  })
}

/**
 * 设置推送渠道
 * @param channel  EMAIL :EMAIL SMS :SMS INNER_MESSAGE :INNER_MESSAGE {"EMAIL":"EMAIL","SMS":"SMS","INNER_MESSAGE":"INNER_MESSAGE"}
 * @param enable
 * @returns {Promise<any>}
 */
export const postNotificationChannel = ({ channel, enable }) => {
  return request({
    url: `/middleware/v1/inner_message/channel/${channel}`,
    method: 'POST',
    params: {
      enable
    }
  })
}
